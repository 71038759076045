import React from 'react';
import {
  DontDo,
  DontDoItem,
  PageHero,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Mobile Notifications" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Writing Guidelines" tierThree="Mobile Notifications" />

      <Section>
        <Paragraph>
          Given the legal requirements in collecting phone numbers and using that contact information,{' '}
          <strong>we should never send SMS messages directly from Hudl</strong>.
        </Paragraph>
        <Paragraph>
          Push notifications are perfect for quick, time-sensitive alerts related to activity the user can perform in
          the Hudl app. (For longer updates,{' '}
          <Link href="/words/writing-guidelines/transactional-emails">try an email</Link>
          .)
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Things to Keep in Mind</SectionSubhead>
        <List type="unordered">
          <li>The user must purposely choose to receive your notifications via a toggle in the app’s settings.</li>
          <li>
            Receiving <em>and</em> opening a notification require data. Be respectful in how often you send these
            alerts, bundling whenever possible.
          </li>
          <li>
            We have other ways of contacting a user and plenty of reasons to do so. Be aware of the other notifications
            they may be receiving, either from the app or via email.
          </li>
        </List>
      </Section>
      <Section>
        <SectionSubhead>Length</SectionSubhead>
        <Paragraph>
          Seeing as push notifications have the most limited real estate of any channel we use, length is important.
        </Paragraph>
        <Paragraph>
          How many characters appear depends on screen size and where they’re viewing the notification: on their lock
          screen, in the notification center or as a banner.
        </Paragraph>
        <Paragraph>
          The banner will likely cut whatever you put together (sometimes to as few as 40 characters), but if you can{' '}
          <strong>keep the message around 100 characters</strong>, most of it should appear on the lock screen and in
          the notification center.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem type="dont" text="ramble and give everything away." img="mobilenotifications-length-dont" />
          <DontDoItem type="do" text="stick to the point." img="mobilenotifications-length-do" />
        </DontDo>
      </Section>
      <Section>
        <SectionSubhead>Purpose</SectionSubhead>
        <Paragraph>
          You could be sending a push notification for one of two reasons: You’re either asking the user to do something
          or shooting them an FYI.
        </Paragraph>
        <Paragraph>
          If you’re asking the user to do something, make that clear in the notification itself. Don’t “tease”
          anything—and if the action requires an internet connection, say it upfront.
        </Paragraph>
        <Paragraph>
          When you shoot them an FYI, make sure the most important details are in that notification. It shouldn’t be so
          vague or irrelevant that they have to open the app to know what we’re talking about.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="be so vague they have no reason or desire to engage."
            img="mobilenotification-purpose-dont"
          />
          <DontDoItem type="do" text="provide clear context and next steps." img="mobilenotification-purpose-do" />
        </DontDo>
      </Section>
      <Section>
        <SectionSubhead>Personalization</SectionSubhead>
        <Paragraph>
          As with emails, custom details can go a long way. Mention their team or a coach/athlete they’re familiar with
          to get their attention (assuming it fits the narrative).
        </Paragraph>
        <Paragraph>
          <strong>Do not personalize with their first name.</strong> It’s kind of creepy.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Punctuation</SectionSubhead>
        <Paragraph>
          <strong>You get one exclamation mark per week</strong>
          —use it wisely. (And no, they don’t roll over.)
        </Paragraph>
        <Paragraph>
          If you ask a question, the answer should also be in that notification. We shouldn’t trick them into opening
          anything.
        </Paragraph>
        <Paragraph>For everything else, use a period.</Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
